<template>
  <div class="sectionContainer">
    <div class="centeredInnerSection">
      <div>
        <input type="checkbox" id="question1" name="q" class="FAQquestions" />
        <label for="question1" class="FAQquestion">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Nulla, modi.
        </label>
        <div class="FAQanswers">Lorem ipsum dolor sit amet.</div>
      </div>
      <div>
        <input type="checkbox" id="question2" name="q" class="FAQquestions" />
        <label for="question2" class="FAQquestion">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Eligendi
          impedit incidunt dolor quis asperiores cumque assumenda, expedita
          magnam! Facere doloribus aperiam deleniti nobis incidunt laborum
          reprehenderit corrupti quas aliquam atque.
        </label>
        <div class="FAQanswers">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis,
          asperiores modi quos ab ipsam quo hic natus, suscipit accusamus
          ratione laboriosam nihil cum officia, voluptates adipisci earum id eum
          quae dignissimos magni placeat dolorum. Explicabo nam velit nihil
          inventore pariatur debitis dolorum repellat, doloremque consequatur
          assumenda odit sit rerum amet ullam nobis voluptas officia aut quae,
          placeat sint maiores? Voluptatem.
        </div>
      </div>
      <div>
        <input type="checkbox" id="question3" name="q" class="FAQquestions" />
        <label for="question3" class="FAQquestion">
          This is the question that will be asked?
        </label>
        <div class="FAQanswers">
          This is the answer of the question.. keep it short.
        </div>
      </div>
      <div>
        <input type="checkbox" id="question4" name="q" class="FAQquestions" />
        <label for="question4" class="FAQquestion">
          This is the question that will be asked?
        </label>
        <div class="FAQanswers">
          This is the answer of the question.. keep it short.
        </div>
      </div>
      <div>
        <input type="checkbox" id="question5" name="q" class="FAQquestions" />
        <label for="question5" class="FAQquestion">
          This is the question that will be asked?
        </label>
        <div class="FAQanswers">
          This is the answer of the question.. keep it short.
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped></style>
